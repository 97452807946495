import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import 'flag-icons/css/flag-icons.min.css';

import { LangContext } from 'context/LangContext';
import Field from 'components/player/pageParts/Field';
import PlayerStatistics from 'components/player/pageParts/Statistics';
import WyscoutStatistics from 'components/player/pageParts/WyscoutStatistics';

import PlayerAchievements from 'components/player/pageParts/Achievements';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';
import * as style from 'styles/components/player/page/profile.module.scss';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const PlayerProfile = ({ player, playerStatistics }) => {
    const { lang } = useContext(LangContext);

    const getFormattedBirthday = (date) =>
        new Date(date).toLocaleDateString(lang.iso, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });

    const getFormattedDate = (date) =>
        new Date(date).toLocaleDateString(lang.iso, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });

    const getPlayerAge = (birth) => {
        const age = new Date();
        age.setTime(Date.parse(Date()) - Date.parse(birth));

        return age.getFullYear() - 1970;
    };

    // const isStats = playerStatistics && playerStatistics.length !== 0;
    const isStats = false;
    const isWyscoutStats = player.wyscoutId && player.wyscoutId.length !== 0;
    // const isWyscoutStats = false;

    return (
        <div className={`${style.profile__container} wrapper`}>
            <div className={style.profile__visualContainer}>
                <div className={style.profile__visual}>
                    {player.image && player.image.length !== 0 && (
                        <ImageGatsby
                            {...player.image[0].image}
                            height={800}
                            alt={`${player.firstName} ${player.lastName}`}
                            title={`${player.firstName} ${player.lastName}`}
                        />
                    )}
                </div>

                <div className={style.profile__socialContainer}>
                    <div>
                        {player.jerseyBuyLink && (
                            <a
                                href={player.jerseyBuyLink}
                                target="_blank"
                                className={style.profile__getJersey}
                                rel="noreferrer"
                                aria-label={getFormattedMessage('player.getJersey', lang)}
                            >
                                <FormattedMessage id="player.getJersey" />
                            </a>
                        )}
                    </div>

                    <div>
                        {player.twitter && (
                            <a
                                href={player.twitter}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`${player.firstName} ${player.lastName} ${
                                    MESSAGES[lang.translationKey].player.twitter
                                }`}
                            >
                                <i className="ico-twitter" aria-hidden />
                            </a>
                        )}

                        {player.instagram && (
                            <a
                                href={player.instagram}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`${player.firstName} ${player.lastName} ${
                                    MESSAGES[lang.translationKey].player.instagram
                                }`}
                            >
                                <i className="ico-instagram" aria-hidden />
                            </a>
                        )}
                    </div>
                </div>
            </div>

            {isStats && (
                <div className={style.profile__statContainer}>
                    <PlayerStatistics playerStatistics={playerStatistics} />
                </div>
            )}

            <div className={`${style.profile__aboutContainer} ${isStats ? '' : 'no-stat'}`}>
            {isWyscoutStats && (
                <div className={style.profile__statContainer}>
                    <WyscoutStatistics player={player} />
                </div>
            )}
                <h2 className="title-xs text-bold small-margin">
                    <FormattedMessage id="player.about" />
                </h2>

                <div className={style.profile__aboutSection}>
                    <div className={style.profile__info}>
                        <dl>
                            {player.birthday && (
                                <>
                                    <dt>
                                        <FormattedMessage id="player.birthday" />
                                    </dt>
                                    <dd>
                                        {getFormattedBirthday(player.birthday)}
                                        {` (${
                                            MESSAGES[lang.translationKey].player.age
                                        } ${getPlayerAge(player.birthday)} ${
                                            MESSAGES[lang.translationKey].player.years
                                        })`}
                                    </dd>
                                </>
                            )}

                            {player.height && player.weight && (
                                <>
                                    <dt>
                                        <FormattedMessage id="player.measures" />
                                    </dt>
                                    <dd>
                                        {player.height / 100}m / {player.weight}kg
                                    </dd>
                                </>
                            )}

                            {player.height && !player.weight && (
                                <>
                                    <dt>
                                        <FormattedMessage id="player.height" />
                                    </dt>
                                    <dd>
                                        {player.height / 100}m
                                    </dd>
                                </>
                            )}

                            {/* TODO: Player skills are not yet in sanity */}
                            {player.skills && player.skills.length !== 0 && (
                                <>
                                    <dt>
                                        <FormattedMessage id="player.skills" />
                                    </dt>
                                    <dd>{player.skills}</dd>
                                </>
                            )}

                            {player.signedHammarby && (
                                <>
                                    <dt>
                                        <FormattedMessage id="player.signedHammarby" />
                                    </dt>
                                    <dd>{getFormattedDate(player.signedHammarby)}</dd>
                                </>
                            )}

                            {player?.nationality.length !== 0 &&
                                player.nationality[0].title[lang.translationKey] && (
                                    <>
                                        <dt>
                                            <FormattedMessage id="player.nationality" />
                                        </dt>
                                        <dd>
                                            {player.nationality[0].title[lang.translationKey]}
                                            <span
                                                className={`fi fi-${player.nationality[0].ref}`}
                                            />
                                        </dd>
                                    </>
                                )}
                        </dl>
                    </div>

                    <Field position={player.detailedPosition} />
                </div>

                {player.bio && player.bio.length !== 0 && (
                    <>
                        <h2 className="title-xs text-bold small-margin">
                            <FormattedMessage id="player.bio" />
                        </h2>

                        <p className={`${style.profile__bio} text-sm-mobile text-md`}>
                            {player.bio}
                        </p>
                    </>
                )}

                {player.quote && player.quote.length !== 0 && (
                    <div className={style.profile__quote}>
                        <i className="ico-quote" aria-hidden />
                        <p className="text-xl-mobile title-sm text-bold">{player.quote}</p>
                    </div>
                )}

                {player.achievement && player.achievement.length !== 0 && (
                    <PlayerAchievements achievements={player.achievement} />
                )}
            </div>
        </div>
    );
};

export default PlayerProfile;
